<template>
	<a href="#save" class="save-button btn-secondary" :class="classes" title="Save this vehicle. No account needed" @click.prevent="toggleSaved()">{{saveText}}</a>
</template>
<script>
import config from '@/config';
import { mapMutations } from 'vuex';
import _ from 'lodash';

export default {
	components: {
	},
	data() {
		return {
		}
	},
	computed: {
		classes(){
			if(this.savedIndex === -1){
				return " ";
			}else{
				return " is-saved";
			}
		},
		saveText(){
			if(this.savedIndex === -1){
				return "Save";
			}else{
				return "Saved";
			}
		},
		savedIndex(){
			var r = -1;
			var local = this;
			this.$store.state.saved.cars.forEach(function(car, i){
				if(car.vin == local.vin){
					r = i;
				}
			});
			return r;
			// return this.$store.state.saved.cars.indexOf(this.vin);
		}
	},
	methods: {
		toggleSaved(){
			if(this.savedIndex === -1){
				this.add(this.vin);
			}else{
				this.remove(this.vin);
			}
		},
		...mapMutations({
			add: 'saved/add',
			remove: 'saved/remove'
		})
	},
	props: [
		'vin'
	],
	watch: {
	},
	mounted(){

	},
}
</script>
<style lang="scss">
.save-button{
	&.is-saved, &.is-saved:focus{
		color: #FFFFFF;
		background-color: #000000!important;
		border: 2px solid #000000!important;
	}
}
</style>
