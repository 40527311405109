<template>
	<div class="editable-text" title="Click to edit">
		<div v-if="!editing">
			<span class='text' v-if="value" @click="enableEditing">{{value}}</span>
			<span class='text' v-if="!value" @click="enableEditing">Click here to add a note</span>
		</div>
		<div v-if="editing">
			<textarea v-model="tempValue" class="input"/>
			<button @click="disableEditing"> Cancel </button>
			<button @click="saveEdit"> Save </button>
		</div>
	</div>
</template>
<script>
import config from '@/config';
import _ from 'lodash';

export default {
	components: {
	},
	data() {
		return {
			editing: false,
			tempValue: '',
		}
	},
	computed: {
	},
	methods: {
		enableEditing: function(){
			this.tempValue = this.value;
			this.editing = true;
		},
		disableEditing: function(){
			this.tempValue = null;
			this.editing = false;
		},
		saveEdit: function(){
			this.$emit('saved', this.tempValue);
			this.disableEditing();
		}
	},
	props: [
		'value'
	],
	watch: {
	},
	mounted(){

	},
}
</script>
<style lang="scss">
.editable-text{
	cursor: pointer;
	border: 1px solid white;
	padding: 6px;
	&:hover{
		border: 1px solid #e91b0e;
	}
	.text{
		white-space: pre;
	}
	span{
		width: 100%;
		height: 100%;
	}
	textarea{
		width: 100%;
		height: 120px;

	}
}
</style>
