<template>
	<div class="vehicle-card">
		<div class="row">
			<div class="col-sm-3">
				<img v-if="imagePath" class="img-fluid" :src="imagePath" loading="lazy" alt="Vehicle Image"/>
			</div>
			<div class="col-sm-5">
				<h2 class="model">{{car.year}} {{car.model | fix_model_name}} {{car.badge}}</h2>
				<h3 class="price" :class="car.class">{{car.used_vehicle_price | currency}} <span>| {{Number(car.odometer).toLocaleString()}} miles</span></h3>
				<h4>Located: <span>{{car.metro_name}}</span></h4>
				<h4 class="vin">VIN:&nbsp;<span>{{car.vin}}</span></h4>
				<h4 class="status">Status: <span>{{car.status}}</span></h4>

				<div class="actions">
					<nuxt-link :to="'/vin/'+car.vin" class="btn-primary">DETAILS</nuxt-link>
					<saved :vin="car.vin"></saved>
				</div>
			</div>
			<div class="col-sm-4 features">
				<div class="section-top">Features</div>
				<p>
					<strong type="is-warning" v-if="car.preowned_warranty_eligibility == 'Pre-Owned Extended'">Limited Warranty</strong>
					<strong type="is-danger"  v-if="car.vehicle_history != 'CLEAN' && car.vehicle_history != ''">{{car.vehicle_history}}</strong>
				</p>
				<p class="features-details">
					<span v-html="car.features"></span>
				</p>
			</div>
		</div>
		<div class="row saved-details" v-if="saved">
			<div class="col-sm-3">Saved {{saved.date_of | moment("from")}}</div>
			<div class="col-sm-9">
				Your Notes: <editable-input :value="saved.note" @saved="_updateSavedNote"/>
			</div>
		</div>
	</div>
</template>
<script>
import config from '@/config';
import SavedButton from '@/components/SavedButton';
import EditableInput from '@/components/EditableInput';
import { mapMutations } from 'vuex';
import _ from 'lodash';

export default {
	components: {
		saved: SavedButton,
		editableInput: EditableInput
	},
	data() {
		return {
		}
	},
	computed: {
		imagePath(){
			if(!_.isNil(this.car.images) && !_.isNil(this.car.images[0])){
				return this.car.images[0];
			}else{
				var model = "ms";
				var angle = "STUD_3QTR_V2";
				if(this.car.model === "MODEL_X"){model = "mx";angle = "STUD_3QTR";}
				if(this.car.model === "MODEL_3"){model = "m3";angle = "STUD_3QTR";}
				if(this.car.model === "MODEL_Y" || this.car.model === "my" ){model = "my";angle = "STUD_3QTR";}
				var url = encodeURIComponent("model="+model+"&view="+angle+"&size=1920&context=inventory&bkba_opt=2&options="+this.car.option_code_list);
				return config.IMAGE_URL+"?path="+encodeURIComponent(url);
			}
		},
	},
	methods: {
		_updateSavedNote(value){
			this.updateSavedNote({vin: this.car.vin, note: value});
		},
		...mapMutations({
			updateSavedNote: 'saved/updateNote',
		})

	},
	props: [
		'car',
		'saved'
	],
	watch: {
	},
	mounted(){

	},
}
</script>
<style lang="scss">
.vehicle-card{
	margin-bottom: 60px;
	border-bottom: 1px solid #dddddd;

	h2{
		margin-bottom: 5px;
		font-size: 22px;
		line-height: 27px;
		letter-spacing: 0.47px;
		font-weight: normal;
	}
	.model{
		text-transform: uppercase;
	}
	h3{
		margin-bottom: 5px;
		font-size: 40px;
		line-height: 49px;
		letter-spacing: 0.86px;
		color: $black;
		font-weight: normal;
		span{
			display: inline-block;
			margin-left: 10px;
			font-size: 15px;
			line-height: 19px;
			letter-spacing: 0.32px;
			color: $black;
			font-weight: 600;
			text-decoration: none;
			vertical-align: middle;
		}
	}
	h4, .archive-pricing, .features{
		margin-bottom: 11px;
		font-size: 15px;
		line-height: 19px;
		letter-spacing: 0.32px;
		color: $black;
		span{
			font-weight: 600;
		}
		&.vin{
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}
	.actions{
		margin: 19px 0px 60px;
	}
	.features{
		.section-top{
			font-size: 14px;
			line-height: 21px;
			color: $black;
			letter-spacing: 0.3px;
			margin-bottom: 5px;
			font-weight: 600;
		}
		p{
			margin: 0px;
			font-size: 14px;
			line-height: 21px;
			color: $black;
			letter-spacing: 0.3px;
		}
		.features-details{
			max-height: 260px;
			overflow: scroll;
			.expandable-text-line{
				height: 200px;
				white-space: normal;
			}
		}
	}
	.features{
		line-height: 31px;
		span{
			font-weight: normal;
		}
	}



}
</style>
