<template>
	<div class="content">
		<div class="container inventory">
			<div class="row">
				<div class="col-sm-3 left-side">
					<h2>
						Inventory
						<div class="mobile-filter-toggle">
							<a @click.prevent="toggleFilter" href="#filter-toggle"><img src="~assets/filter.svg" alt="Filter"/></a>
						</div>

					</h2>
					<div class="mobile-filter" :class="{'mobile-active': isFilterActive}">
						<label>
							For Sale / Sold
							<select class="form-control" placeholder="Select a model" v-model="filterSold">
								<option v-for="item in sold_options" :key="item.id" :value="item.id">
									{{ item.name }}
								</option>
							</select>
						</label>
						<label>
							Model
							<select class="form-control" placeholder="Select a model" v-model="filterModel">
								<option v-for="item in models" :key="item.id" :value="item.id">
									{{ item.name }}
								</option>
							</select>
						</label>
						<label>
							Used / New
							<select class="form-control" placeholder="Select a model" v-model="filterStatus">
								<option v-for="item in status_options" :key="item.id" :value="item.id">
									{{ item.name }}
								</option>
							</select>
						</label>
						<label>
							Trim
							<select class="form-control" placeholder="Select a model" v-model="filterTrim">
								<option v-for="item in trim_options" :key="item.id" :value="item">
									{{ item.name }}
								</option>
							</select>
						</label>
						<label>
							Year
							<select class="form-control" placeholder="Select a model" v-model="filterYear">
								<option v-for="item in year_options" :key="item.id" :value="item.id">
									{{ item.name }}
								</option>
							</select>
						</label>

						<label>
							Features
							<client-only>
								<tags-input ref="featureList" element-id="tags"
									v-model="selectedFeatures"
									@tags-updated="onFeatureTagsUpdated"
									:existing-tags="filterOptions"
									id-field="id"
									text-field="name"
									typeahead-style="dropdown"
									:typeahead-always-show="false"
									:typeahead-show-on-focus="true"
									:typeahead-activation-threshold="threshold"
									:typeahead-hide-discard="true"
									placeholder="Choose a feature"
									:typeahead="true"
									@focus="typeAheadFocus"
									@blur="typeAheadBlur"></tags-input>
							</client-only>
						</label>
					</div>
				</div>
				<div class="col-sm-9 right-side">

					<div class="row">
						<div class="col-sm-12 col-md-8">
							<client-only>
								<div class="filters" v-if="filters">
									<span @click="clearFilters" v-if="hasFilters"><strong>CLEAR</strong></span>
									<span @click="updateFilter('model', '')" v-if="filters.model">{{filters.model}}</span>
									<span @click="updateFilter('vin', '')" v-if="filters.vin">VIN #{{filters.vin}}</span>
									<span @click="updateFilter('sold', 2)" v-if="filters.sold == 1">Sold</span>
									<span @click="updateFilter('sold', 2)" v-if="filters.sold == 0">Sold and For Sale</span>
									<span @click="updateFilter('status', '')" v-if="filters.status == 'used'">Used</span>
									<span @click="updateFilter('status', '')" v-if="filters.status == 'new'">Inventory</span>
									<span @click="updateFilter('year', '')" v-if="filters.year !== ''">Model Year: {{filters.year}}</span>
									<span v-for="feature in filters.features" :key="feature.id" @click="removeFeature(feature)" >{{feature.name}}</span>
									<span @click="createAlert()" v-if="hasFilters" class="primary">Create New Alert</span>
								</div>
							</client-only>
						</div>
						<div class="col-sm-12 col-md-4 sort-filter">
							<label>
								Sort by:
								<select class="form-control inline" placeholder="Select a model" v-model="filterSort">
									<option v-for="item in sortOptions" :key="item.id" :value="item.id">
										{{ item.name }}
									</option>
								</select>
							</label>
						</div>
					</div>

					<vehicle :car="car" v-for="car in pagedCars" :key="car.vin"></vehicle>

					<ul class="page-numbers" v-if="totalPageCount">
						<li class="previous">
							<nuxt-link v-if="currentPage - 1 > 0" :to="{ path: '/inventory', query: { page: currentPage-1 } }">Previous</nuxt-link>
						</li>
						<li v-for="num in this.pageNumbers" v-if="num != null">
							<nuxt-link v-if="num != $route.query.page && num != currentPage" :to="{ path: '/inventory', query: { page: num } }">{{ num }}</nuxt-link>
							<span v-else>{{ num }}</span>
						</li>
						<li class="next">
							<nuxt-link v-if="totalPageCount >= currentPage + 1" :to="{ path: '/inventory', query: { page: currentPage+1 } }">Next</nuxt-link>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import config from '@/config';
import SavedButton from '@/components/SavedButton';
import VehicleCard from '@/components/VehicleCard';
import { mapMutations } from 'vuex';
import _ from 'lodash';
import axios from 'axios';
export default {
	components: {
		saved: SavedButton,
		vehicle: VehicleCard
	},
	props: ['initialcars', 'initialfeatureoptions'],
	data() {
		return {
			prevpage: null,
			threshold: 1,
			nextpage: null,
			currentPage: 1,
			hasFilters: false,
			pageNumbers: [],
			pageNumberCount: 0,
			totalPageCount: 0,
			numPerPage: 20,
			alertCreated: false,
			cars: this.initialcars,
			isFilterActive: false,
			selectedFeatures: [],
			feature_options: this.initialfeatureoptions,
			filterTrim: [],
			sold_options: [{id: 0, name: "All"}, {id: 1, name: "Only Sold"}, {id: 2, name: "Only For Sale"}],
			status_options: [{id: "", name: "All"}, {id: "used", name: "Only Used"}, {id: "new", name: "Only New / Inventory"}],
			models: [{id: "", name: "All"}, {id: "MODEL_S", name: "Model S"}, {id: "MODEL_3", name: "Model 3"}, {id: "MODEL_X", name: "Model X"}, {id: "my", name: "Model Y"}, {id: "ct", name: "Cybertruck"}],
			sortOptions: [{id: "date_added DESC", name: "Recent"}, {id: "date_added ASC", name: "Oldest"}, {id: "used_vehicle_price DESC", name: "Highest Price"}, {id: "used_vehicle_price ASC", name: "Lowest Price"}]
		}
	},
	methods: {
		clearFilters(){
			var payload = {'filter': 'model', 'value': ''};
			this.$store.commit("filters/setFilter", payload);
			payload = {'filter': 'sort', 'value': 'date_added DESC'};
			this.$store.commit("filters/setFilter", payload);
			payload = {'filter': 'status', 'value': ''};
			this.$store.commit("filters/setFilter", payload);
			payload = {'filter': 'sold', 'value': 2};
			this.$store.commit("filters/setFilter", payload);
			payload = {'filter': 'vin', 'value': ''};
			this.$store.commit("filters/setFilter", payload);
			payload = {'filter': 'year', 'value': ''};
			this.$store.commit("filters/setFilter", payload);
			payload = {'filter': 'features', 'value': []};
			this.$store.commit("filters/setFilter", payload);
			this.selectedFeatures = [];
			this.filterTrim = [];
			this.filterYear = [];
			this.hasFilters = false;

			this.updateData();
		},
		updateFilter(type, value){
			this.hasFilters = true;
			var payload = {'filter': type, 'value': value};
			this.$store.commit("filters/setFilter", payload);
			this.updateData();
		},
		removeFeature(feature){
			const pos = this.selectedFeatures.findIndex(el => el.id === feature.id);
			if(pos >= 0){
			    this.selectedFeatures.splice(pos, 1);
				this.onFeatureTagsUpdated();
			}
		},
		typeAheadFocus(){
			this.threshold = 0;
		},
		typeAheadBlur(){
			this.threshold = 1;
		},
		onFeatureTagsUpdated(){
			this.filterFeatures = [...this.selectedFeatures];
		},
		toggleFilter(){
			this.isFilterActive = !this.isFilterActive;
		},
		updateData(){
			var local = this;
			let formData = {
				action: "query",
				sort: this.$store.state.filters.sort,
				model: this.$store.state.filters.model,
				sold: this.$store.state.filters.sold,
				year: this.$store.state.filters.year,
				filter: this.$store.state.filters.filter,
				vin: this.$store.state.filters.vin,
				status: this.$store.state.filters.status,
			};

			local.cars = [];
			const encodeForm = (data) => {
				return Object.keys(data)
					.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
					.join('&');
			}

			var queryString = encodeForm(formData);
			this.$store.state.filters.features.map(function(value, key){
				queryString += "&features[]="+ encodeURIComponent(value.name);
			});

			if(queryString != "action=query&sort=date_added%20DESC&model=&sold=2&filter=&vin=&status="){
				this.hasFilters = true;
			}

			return axios.post(config.API_URL+"?"+queryString, queryString).then(response => {
				if(response.data.status == true){
					// && response.data.data.length > 0
					local.cars = response.data.data;
					local.totalPageCount = Math.ceil(local.cars.length / local.numPerPage);
					local.setPageNumbers();
				}else{
					local.cars = [];
				}
			}, err_response => {
				console.log("Error getting cars:", err_response);
			});
		},
		setPageNumbers(){
      		let _currentPage = this.$route.query.page ? this.$route.query.page : 1;
			this.currentPage = _currentPage;
			this.setPages(_currentPage, this.totalPageCount);
    	},
		setPages(currentPage, totalPageCount){
			this.prevpage = currentPage > 1 ? (currentPage - 1) : null;
			this.pageNumbers = [];
			if (!totalPageCount) {
				this.nextpage = this.$route.query.page ? (parseInt(this.$route.query.page) + 1) : 2;
			} else {
				this.nextpage = currentPage < totalPageCount ? (parseInt(currentPage) + 1) : null;
			}
			for (let i = 0; i < 7; i++) {
				let _p = ((parseInt(currentPage) - 4) + i)
				if (_p > 0 && _p <= totalPageCount) {
					this.pageNumbers.push(_p);
					this.pageNumberCount++;
				} else{
					this.pageNumbers.push(null);
				}
			}
		},
		createAlert(){
			if(!this.$auth.loggedIn){
				this.$auth.loginWith("awsCognito");
			}else{
				let formData = {
					action: "create_alert",
					model: this.$store.state.filters.model,
					sold: this.$store.state.filters.sold,
					vin: this.$store.state.filters.vin,
					year: this.$store.state.filters.year,
				};

				const encodeForm = (data) => {
					return Object.keys(data)
						.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
						.join('&');
				}

				var queryString = encodeForm(formData);
				this.$store.state.filters.features.map(function(value, key){
					queryString += "&features[]="+ encodeURIComponent(value.name);
				});

				return axios.post(config.API_URL+"?"+queryString, queryString, {headers: {"Authorization" : `Bearer ${this.$auth.strategy.token.get()}`}}).then(response => {
					if(response.data.status == true){
						this.$nuxt._router.push("/alerts");
					}else{
						return null;
					}
				}, err_response => {
					console.log("Error creating alerts:", err_response);
				});

			}
		}
	},
	watch: {
		$route() {
			this.setPageNumbers();
      	},
		filterTrim(val, oldVal){
			if(oldVal !== ""){
				this.removeFeature(oldVal);
			}
			if(val.id !== undefined && val.id.length >= 1){
				let selectedTrim = [val];
				this.selectedFeatures = [...selectedTrim];
				this.filterFeatures = [...selectedTrim];
			}
		}
	},
	computed: {
		pagedCars(){
			return this.cars.slice(((this.currentPage-1) * this.numPerPage), this.currentPage * this.numPerPage);
		},
		filters(){
			return this.$store.state.filters;
		},
		filterStatus: {
			set(val){
				var payload = {'filter': 'status', 'value': val};
				this.$store.commit("filters/setFilter", payload);
				this.updateData();
			},
			get(){
				return this.$store.state.filters.status;
			}
		},
		filterYear: {
			set(val){
				var payload = {'filter': 'year', 'value': val};
				this.$store.commit("filters/setFilter", payload);
				this.updateData();
			},
			get(){
				return this.$store.state.filters.year;
			}
		},
		filterSold: {
			set(val){
				console.log(val);
				var payload = {'filter': 'sold', 'value': val};
				this.$store.commit("filters/setFilter", payload);
				this.updateData();
			},
			get(){
				return this.$store.state.filters.sold;
			}
		},
		filterFeatures: {
			set(val){
				var payload = {'filter': 'features', 'value': val};
				this.$store.commit("filters/setFilter", payload);
				this.updateData();
			},
			get(){
				return this.$store.state.filters.features;
			}
		},
		filterSort: {
			set(val){
				var payload = {'filter': 'sort', 'value': val};
				this.$store.commit("filters/setFilter", payload);
				this.updateData();
			},
			get(){
				return this.$store.state.filters.sort;
			}
		},
		filterModel:{
			set(val){
				var payload = {'filter': 'model', 'value': val};
				this.$store.commit("filters/setFilter", payload);
				this.updateData();
			},
			get(){
				return this.$store.state.filters.model;
			}
		},
		trim_options(){
			var res = [];
			res.push({id: "", name: "All", is_trim: 1});
			this.filterOptions.forEach(option => {
				if(option.is_trim == 1){
					res.push(option);
				}
			});
			return res;
		},
		year_options(){
			var res = [];
			res.push({id: "", name: "All"});
			this.foundYears.forEach(option => {
				res.push(option);
			});
			return res;
		},

		filterOptions(){
			var res = [];
			var foundFeatures = [];
			if(this.cars !== undefined){
				this.cars.forEach(car => {
					car.features.split("<br/>").forEach(feature => {
						foundFeatures.push(feature);
					});
				});
			}

			//unique
			foundFeatures = [...new Set(foundFeatures)];
			if(this.feature_options !== undefined){
				this.feature_options.forEach(feature => {
					if(foundFeatures.includes(feature.name)){
						res.push(feature);
					}
				});
			}
			return res;
		},
		foundYears(){
			var res = [];
			var foundYears = [];
			if(this.cars !== undefined){
				this.cars.forEach(car => {
					foundYears.push(car.year);
				});
			}

			//unique
			foundYears = [...new Set(foundYears)];
			foundYears.forEach(year => {
				res.push({id: year, name: year});
			});
			return res;
		}
	},
	mounted(){
		var local = this;
		//state isn't available yet?
		setTimeout(function(){
			local.selectedFeatures = [...local.$store.state.filters.features];
			local.updateData();

			local.totalPageCount = Math.ceil(local.cars.length / local.numPerPage);
			local.setPageNumbers();
			// local.$refs.featureList.$refs.taginput.focus();
			// local.$refs.featureList.$refs.taginput.blur();
		}, 300);
	},
	fetch(){
		// this.updateData();
	}

}
</script>
<style lang="scss">
</style>
